exports.components = {
  "component---src-pages-3-d-gltf-loader-jsx": () => import("./../../../src/pages/3d/gltf-loader.jsx" /* webpackChunkName: "component---src-pages-3-d-gltf-loader-jsx" */),
  "component---src-pages-3-d-proton-gun-jsx": () => import("./../../../src/pages/3d/proton-gun.jsx" /* webpackChunkName: "component---src-pages-3-d-proton-gun-jsx" */),
  "component---src-pages-3-d-react-spring-jsx": () => import("./../../../src/pages/3d/react-spring.jsx" /* webpackChunkName: "component---src-pages-3-d-react-spring-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-index-js": () => import("./../../../src/pages/kitchen/index.js" /* webpackChunkName: "component---src-pages-kitchen-index-js" */),
  "component---src-pages-pizzas-index-js": () => import("./../../../src/pages/pizzas/index.js" /* webpackChunkName: "component---src-pages-pizzas-index-js" */),
  "component---src-pages-resume-index-jsx": () => import("./../../../src/pages/resume/index.jsx" /* webpackChunkName: "component---src-pages-resume-index-jsx" */),
  "component---src-pages-testing-index-jsx": () => import("./../../../src/pages/Testing/index.jsx" /* webpackChunkName: "component---src-pages-testing-index-jsx" */),
  "component---src-pages-testing-two-jsx": () => import("./../../../src/pages/Testing/two.jsx" /* webpackChunkName: "component---src-pages-testing-two-jsx" */),
  "component---src-pages-three-index-jsx": () => import("./../../../src/pages/three/index.jsx" /* webpackChunkName: "component---src-pages-three-index-jsx" */),
  "component---src-pages-uses-index-jsx": () => import("./../../../src/pages/uses/index.jsx" /* webpackChunkName: "component---src-pages-uses-index-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-pizza-index-js": () => import("./../../../src/templates/pizza/index.js" /* webpackChunkName: "component---src-templates-pizza-index-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

